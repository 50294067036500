class Navigation {
    _sOpen = 'open';
    _sClosed = 'closed';
    menu;
    menuBtn;
    menuElements;
    menuBreakpoint;
    menuSelector;
    menuBtnSelector;
    menuElementSelector;

    constructor({
                    menuSelector = '',
                    menuElementSelector = ''
                }) {

        this.menuSelector = menuSelector ? menuSelector : '.module-emotion .navigation'
        this.menuElementSelector = menuElementSelector ? menuElementSelector : '.link-group';

        this.prepare();
    }

    prepare() {

        this.menu = document.querySelector(this.menuSelector);

        if (this.menu !== null) {
            //this.menuBtn = this.menu.querySelector(this.menuBtnSelector);
            //this.menuBreakpoint = parseInt(this.menu.dataset.breakpoint ?? '0', 10);

            this.menuElements = this.menu.querySelectorAll(this.menuElementSelector);

            if (this.menuElements !== null && this.menuElements.length > 0) {

                this.menuElements.forEach(element => {
                    cl.event.addListener(element, 'click', function () {
                        if(element.dataset.over && element.dataset.over === 'true')
                        {
                            delete element.dataset.over;
                        }
                        else{
                            element.dataset.over = 'true';
                        }

                    });

                    // cl.event.addListener(element, 'mouseout', function () {
                    //     delete element.dataset.over;
                    // });
                });
            }

            // window.addEventListener('resize', () => this.updateMenuByViewport());
            //
            // this.menuBtn.addEventListener('click', () => this.addStatusListener());
            //
            // this.updateMenuByViewport();
        }
    };

    toggleMenuStatus() {

        this.menu.dataset.status = (this.menu.dataset.status === this._sOpen) ? this._sClosed : this._sOpen;
    };

    addStatusListener() {

        this.toggleMenuStatus();
    }

    updateMenuByViewport() {

        this.menu.dataset.viewport = (
            window.innerWidth <= this.menuBreakpoint || this.menuBreakpoint === 0
        ) ? 'mobile' : 'desktop';

        this.setMenuStatus(
            (window.innerWidth > this.menuBreakpoint && this.menuBreakpoint !== 0) ||
            (this.menuBreakpoint === 0 ? false : null)
        );
    };

    setMenuStatus(visibility = null) {

        this.menu.dataset.status = visibility !== true ? this._sClosed : this._sOpen;
    };
}

new Navigation({});
